import React from 'react';
import { MessageDiv, StyledArticle, StyledFileContainer } from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2, StyledTable } from '../../styles/common';
import { SEO } from '../../components/SEO';

export function Head() {
	return (
		<SEO
			title="Wynajem taboru autobusowego i tramwajowego"
			description="Świadczenie usług transportowych."
		/>
	);
}

function PrzewozyPage() {
	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Wynajem taboru autobusowego i tramwajowego
				</StyledH2>
				<MessageDiv>
					<p>
						Świadczenie usług transportowych (w tym okolicznościowe) tramwajami,
						autobusami przegubowymi i standardowymi na terenie miasta
						Bydgoszczy.
					</p>
					<p>
						Kontakt: Zakład Eksploatacji ul. Inowrocławska 11 tel.:&nbsp;
						<a href="tel:523249453">(52) 324-94-53</a>
					</p>
					<h3>Ceny umowne na wynajem autobusu z kierowcą</h3>
					<StyledTable>
						<tr>
							<th>L.p.</th>
							<th>Wyszczególnienie</th>
							<th>Cena netto</th>
							<th>Cena brutto</th>
						</tr>
						<tr>
							<td rowSpan="3" style={{ textAlign: 'center' }}>
								1.
							</td>
							<td colSpan="3">AUTOBUSY KRÓTKIE</td>
						</tr>
						<tr>
							<td>cena 1 wzkm</td>
							<td>9,26 zł</td>
							<td>10,00 zł</td>
						</tr>
						<tr>
							<td>cena 1 godziny</td>
							<td>185,18 zł</td>
							<td>200,00 zł</td>
						</tr>
						<tr>
							<td rowSpan="3" style={{ textAlign: 'center' }}>
								2.
							</td>
							<td colSpan="3">AUTOBUSY DŁUGIE</td>
						</tr>
						<tr>
							<td>cena 1 wzkm</td>
							<td>12,04 zł</td>
							<td>13,00 zł</td>
						</tr>
						<tr>
							<td>cena 1 godziny</td>
							<td>240,74 zł</td>
							<td>260,00 zł</td>
						</tr>
					</StyledTable>
					<p>
						Cenę za 1 wzkm stosuje się, gdy prędkość eksploatacyjna jest większa
						lub równa 20,00 km/godz.
					</p>
					<p>
						Do ustalonej ceny netto należy doliczyć podatek „VAT” wg
						obowiązujących stawek.
					</p>
					<p>
						Cenę brutto stosuje się w przypadku osób fizycznych nieprowadzących
						działalności gospodarczej.
					</p>
					<h3>Ceny umowne na wynajem tramwaju z motorniczym</h3>
					<StyledTable>
						<tr>
							<th>L.p.</th>
							<th>Wyszczególnienie</th>
							<th>Cena netto</th>
							<th>Cena brutto</th>
						</tr>
						<tr>
							<td rowSpan={2} style={{ textAlign: 'center' }}>
								1.
							</td>
							<td colSpan={3}>TRAMWAJ JEDNOWAGONOWY KONSTAL</td>
						</tr>
						<tr>
							<td>cena 1 godziny</td>
							<td>138,89 zł</td>
							<td>150,00 zł</td>
						</tr>
						<tr>
							<td rowSpan={2} style={{ textAlign: 'center' }}>
								2.
							</td>
							<td colSpan={3}>TRAMWAJ DWUWAGONOWY / WIELOCZŁONOWY</td>
						</tr>
						<tr>
							<td>cena 1 godziny</td>
							<td>231,48 zł</td>
							<td>250,00 zł</td>
						</tr>
						<tr>
							<td rowSpan={2} style={{ textAlign: 'center' }}>
								3.
							</td>
							<td colSpan={3}>TRAMWAJ ZABYTKOWY</td>
						</tr>
						<tr>
							<td>cena 1 godziny</td>
							<td>277,78 zł</td>
							<td>300,00 zł</td>
						</tr>
						<tr>
							<td rowSpan={2} style={{ textAlign: 'center' }}>
								4.
							</td>
							<td colSpan={3}>
								TRAMWAJ ZABYTKOWY Z WAGONEM (obsługa dwuosobowa)
							</td>
						</tr>
						<tr>
							<td>cena 1 godziny</td>
							<td>416,67 zł</td>
							<td>450,00 zł</td>
						</tr>
					</StyledTable>
					<p>
						Do ustalonej ceny netto należy doliczyć podatek „VAT” wg
						obowiązujących stawek.
					</p>
					<p>
						Cenę brutto stosuje się w przypadku osób fizycznych nieprowadzących
						działalności gospodarczej.
					</p>
					<h3>Cena umowna na wynajem tramwaju dla potrzeb „nauki jazdy”</h3>
					<br />
					<div style={{ textAlign: 'center' }}>
						<b>TRAMWAJ DWUWAGONOWY</b>
						<br />
						<p>cena 1 godziny 150,00 zł netto</p>
					</div>
					<br />
					<p>
						Do ustalonej ceny należy doliczyć podatek „VAT” wg obowiązujących
						stawek.
					</p>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
}

export default PrzewozyPage;
